import React from 'react';

import udemy from '../../../assets/pictures/projects/udemy.png';
import youtube from '../../../assets/pictures/projects/youtube.png';
import bruteforcer from '../../../assets/pictures/projects/bruteforcer.gif';
import bruteforcerOut from '../../../assets/pictures/projects/bruteforcer-output.gif';

export interface ProjectsPortfolioProps {}

const ArtProjects: React.FC<ProjectsPortfolioProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Projects</h1>
            <br />
            <div className="text-block">
                <p>
                Currently, my projects are primarily focused on education, and I plan to expand these training programs further. Additionally, I aim to develop various tools in the field of Offensive Security and share them with the community. I am actively working on software development to achieve this.
                </p>
            </div>
            <div className="text-block">
                <h2>Youtube Channel</h2>
                <br />
                <p>
                I share educational content on cybersecurity on YouTube. Although I have taken a break from it for a while, I aim to enrich the content I provide there. The videos I share on YouTube are entirely focused on curious and eager learners, with no profit motive involved. Receiving feedback and knowing that I have contributed to someone’s life through this platform brings me great joy.
                </p>
                <br />
                <div className="captioned-image">
                    <img src={youtube} alt="Youtube" />
                    <p>
                        <sub>
                            <b>Figure 1:</b> My Youtube Channel
                        </sub>
                    </p>
                </div>
                <p>You can view the channel by following the link below.</p>
                <ul>
                    <li>
                        <a className='text-decoration-none' rel="noreferrer" target="_blank" href="https://www.youtube.com/@eraycakn">
                            <p>
                                My Youtube Channel - Eray Cakin
                            </p>
                        </a>
                    </li>
                </ul>
                <br />
                <h3>Udemy Education </h3>
                <br />
                
                <div className="captioned-image">
                    <img src={udemy} alt="Udemy" />
                    <p>
                        <sub>
                            <b>Figure 2:</b> Udemy Education - Cybersecurity Basics - On Your Way to Becoming a Pentester
                        </sub>
                    </p>
                </div>
                
                <p>
                I am very happy to share my knowledge and experience in the field of Cyber Security with more than 2,600 students on Udemy. This journey, which adopts the philosophy of <b>'Knowledge multiplies as it is shared'</b>, has been a great experience for both my students and me. In the future, I aim to create more courses, reach wider audiences and continue to share what I know. 
                </p>
                <p>You can review the education by following the link below.</p>
                <ul>
                    <li>
                        <a className='text-decoration-none' rel="noreferrer" target="_blank" href="https://www.udemy.com/course/siber-guvenlik-temelleri-pentester-olma-yolunda/">
                            <p>
                                Cybersecurity Basics - On Your Way to Becoming a Pentester <br/>(Turkish Language)
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Github</h2>
                <br />
                <p>
                I am excited to present the BruteForcer tool, which I developed to streamline penetration testing on internal or external networks. This tool is designed to accelerate brute force attacks by integrating the working principles of various tools. In the near future, I plan to develop more tools and share them on my GitHub profile.
                </p>
                <br />
                <div className="captioned-image">
                    <img src={bruteforcer} alt="BruteForcer Tool" />
                    <p>
                        <sub>
                            <b>Figure 3:</b> BruteForcer Tool (POC)
                        </sub>
                    </p>
                </div>
                <div className="captioned-image">
                    <img src={bruteforcerOut} alt="BruteForcer Tool" />
                    <p>
                        <sub>
                            <b>Figure 4:</b> BruteForcer Tool - Output Example
                        </sub>
                    </p>
                </div>
                <p>
                This is my first tool on GitHub. Due to a busy work schedule, I haven't been able to dedicate much time to tool development projects. However, improving my skills in software development and expanding the collection of tools on my GitHub profile are among my future plans.
                </p>
            </div>
        </div>
    );
};

export default ArtProjects;
