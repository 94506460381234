import React, { useState } from 'react';

import oscp from '../../../assets/pictures/certificates/oscp.png';
import az900 from '../../../assets/pictures/certificates/az900.png';
import iso22301 from '../../../assets/pictures/certificates/iso22301.png';
import iso27001 from '../../../assets/pictures/certificates/iso27001.png';
import tse from '../../../assets/pictures/certificates/tse.png';
import windows from '../../../assets/pictures/certificates/securing-windows.png';
import badge from '../../../assets/pictures/certificates/badge.jpg';
import windowserror from '../../../assets/pictures/windowserror.png';
import windowserrorclose from '../../../assets/pictures/windowserrorclose.png';




export interface CertificatesPortfolioProps {}

const CertificatesPortfolio: React.FC<CertificatesPortfolioProps> = (props) => {
    const [showError, setShowError] = useState(false); // State to manage error popup visibility

    const openError = () => setShowError(true); // Open error popup
    const closeError = () => setShowError(false); // Close error popup

    return (
        <div className="site-page-content">
            <h1>Certificates</h1>
            <h3>My Badges</h3>
            <br />
            <div className='row'>

                {/* OSCP */}
                <div className='col-md-12 mb-4'>
                    <div className="card d-flex flex-row align-items-center">
                        <div className="d-flex flex-column align-items-center">
                            <img className="card-img-left" src={oscp} style={styles.certImg} alt="Offensive Security Certified Professional (OSCP)" />
                            <p style={styles.imgCaption}>Nov 2022</p>
                        </div>
                        <div className="card-body" style={styles.justify}>
                            <p className="card-text">The Offensive Security Certified Professional (OSCP) is a highly respected ethical hacking certification that validates practical penetration testing skills through a hands-on, challenge-based exam.</p>
                        </div>
                        <div className="d-flex">
                            <a href="https://www.credential.net/5a9ed7a7-e309-42df-9b6a-232b9bd25c4d" target='_blank' rel="noreferrer">
                                <img src={badge} style={styles.badgeImg} alt="Badge"/>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Azure Fundamentals */}
                <div className='col-md-12 mb-4'>
                    <div className="card d-flex flex-row align-items-center">
                        <div className="d-flex flex-column align-items-center">
                            <img className="card-img-left" src={az900} style={styles.certImg} alt="Microsoft Certified: Azure Fundamentals (AZ-900)" />
                            <p style={styles.imgCaption}>Jun 2022</p>
                        </div>
                        <div className="card-body" style={styles.justify}>
                            <p className="card-text">Microsoft Certified: The Azure Fundamentals (AZ-900) certification is ideal for those just starting to explore Microsoft Azure by validating foundational knowledge of cloud concepts, Azure services, pricing, and security.</p>
                        </div>
                        <div className="d-flex">
                            <a href="https://www.credly.com/badges/9566ca7a-e9d7-4d35-811d-1080c386e401" target='_blank' rel="noreferrer">
                                <img src={badge} style={styles.badgeImg} alt="Badge"/>
                            </a>
                        </div>
                    </div>
                </div>

                {/* ISO 22301 */}
                <div className='col-md-12 mb-4'>
                    <div className="card d-flex flex-row align-items-center">
                        <div className="d-flex flex-column align-items-center">
                            <img className="card-img-left" src={iso22301} style={styles.certImg} alt="ISO 22301:2019 Business Continuity Management System Lead Auditor" />
                            <p style={styles.imgCaption}>Feb 2022</p>
                        </div>
                        <div className="card-body" style={styles.justify}>
                            <p className="card-text">This certification demonstrates competence in implementing and auditing a Business Continuity Management System (BCMS) to ensure resilience and operational continuity during disruptions.</p>
                        </div>
                        <div className="d-flex">
                            <a href="https://www.credential.net/c76ab5ce-0595-4a7d-89e4-0fd182755081" target='_blank' rel="noreferrer">
                                <img src={badge} style={styles.badgeImg} alt="Badge" />
                            </a>
                        </div>
                    </div>
                </div>

                {/* ISO 27001 */}
                <div className='col-md-12 mb-4'>
                    <div className="card d-flex flex-row align-items-center">
                        <div className="d-flex flex-column align-items-center">
                            <img className="card-img-left" src={iso27001} style={styles.certImg} alt="ISO/IEC 27001:2013 Information Security Management System Lead Auditor" />
                            <p style={styles.imgCaption}>Jan 2022</p>
                        </div>
                        <div className="card-body" style={styles.justify}>
                            <p className="card-text">This certification confirms expertise in setting up, implementing and managing an Information Security Management System (ISMS) to protect sensitive corporate data.</p>
                        </div>
                        <div className="d-flex">
                            <a href="https://www.credential.net/d429afd3-7e09-4bad-908a-93c47e39ff77" target='_blank' rel="noreferrer">
                                <img src={badge} style={styles.badgeImg} alt="Badge"/>
                            </a>
                        </div>
                    </div>
                </div>

                {/* TSE Certification */}
                <div className='col-md-12 mb-4'>
                    <div className="card d-flex flex-row align-items-center">
                        <div className="d-flex flex-column align-items-center">
                            <img className="card-img-left" src={tse} style={styles.certImg} alt="TSE Certified Penetration Testing Specialist" />
                            <p style={styles.imgCaption}>Mar 2022-2025 </p>
                        </div>
                        <div className="card-body" style={styles.justify}>
                            <p className="card-text">The TSE Certified Penetration Tester is a Turkish certification that validates expertise in conducting ethical hacking and penetration testing in accordance with Turkey's specific cybersecurity regulations and standards, similar to globally recognized standards such as CREST.</p>
                        </div>
                        <div className="d-flex">
                        <button type="button" onClick={openError} style={{background: 'none',color: 'blue',textDecoration: 'underline',border: 'none',cursor: 'pointer',padding: 0,}}>
                                <img src={badge} style={styles.badgeImg} alt="Badge"/>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Securing Windows */}
                <div className='col-md-12 mb-4'>
                    <div className="card d-flex flex-row align-items-center">
                        <div className="d-flex flex-column align-items-center">
                            <img className="card-img-left" src={windows} style={styles.certImg} alt="Securing Windows Server 2016 - Microsoft" />
                            <p style={styles.imgCaption}>Mar 2022</p>
                        </div>
                        <div className="card-body" style={styles.justify}>
                            <p className="card-text">This certification validates their ability to secure and manage Windows Server 2016 environments by implementing advanced threat protection and security controls.</p>
                        </div>
                        <div className="d-flex">
                        <button type="button" onClick={openError} style={{background: 'none',color: 'blue',textDecoration: 'underline',border: 'none',cursor: 'pointer',padding: 0,}}>
                                <img src={badge} style={styles.badgeImg} alt="Badge"/>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Windows Error Popup */}
                {showError && (
                    <div style={styles.overlay}>
                    <div style={styles.window}>
                        {/* Popup Header */}
                        <div style={styles.header}>
                        
                        <span style={styles.headerSpan}>Error</span>
                        <button style={styles.closeButton} onClick={closeError}>
                        <img
                            src={windowserrorclose}
                            alt="Error Icon"
                            style={styles.closeButtonicon}
                        />
                        </button>
                        </div>
                        {/* Popup Body */}
                        <div style={styles.body}>
                        <img
                            src={windowserror}
                            alt="Error Icon"
                            style={styles.icon}
                        />
                        <p style={styles.message}>
                        This certificate does not include an online badge as it is outdated or does not have an online feature. 
                        </p>
                        </div>
                        {/* Popup Footer */}
                        <div style={styles.footer}>
                        <button style={styles.okButton} onClick={closeError}>
                            OK
                        </button>
                        </div>
                    </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    certImg: {
        width: '100%',
        margin: 'auto',
        padding: 10,
    },
    badgeImg: {
        width: '100px',
        margin: '0px 0px 25px 0px',
    },
    justify: {
        textAlign: 'justify',
    },
    imgCaption: {
        fontSize: '17px',
        color: '#555',
        textAlign: 'center',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      },
      window: {
        width: '430px',
        backgroundColor: '#ECECEC',
        border: '4px solid rgb(0 100 251)',
        fontSize: '12px',
        display:'grid',
        borderTopLeftRadius:10,
        borderTopRightRadius:10
      },
      header: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgb(0 100 251)',
        color: '#FFF',
        padding: '5px',
      },
      headerSpan:{
        fontSize:20,
        letterSpacing:1.3,
      },
      icon: {
        width: '50px',
        height: '50px',
        margin: '15px 25px 0 10px',
      },
      closeButton: {
        marginLeft: 'auto',
        background: 'none',
        border: 'none',
        color: '#FFF',
        fontWeight: 'bold',
        cursor: 'pointer',
      },
      closeButtonicon:{
        width: '30px',
        height: '30px',
        border:'1px solid white',
        borderRadius:10
      },
      body: {
        padding: '10px',
        color: '#000',
        height:'110px'
      },
      message: {
        marginTop:'10px' ,
      },
      footer: {
        textAlign: 'center',
        padding: '17px 0px 20px',
        justifyContent:'center',
        height:'70px'

      },
      okButton: {
        backgroundColor: '#ECECEC',
        border: '1px solid #000',
        padding: '0px 40px',
        cursor: 'pointer',
        boxShadow:'3px 3px #747272',
        fontSize:16
      },
};

export default CertificatesPortfolio;
