import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import skills from '../../assets/pictures/portfolio/skills.gif';
import certificates from '../../assets/pictures/portfolio/certificate.gif';
import projects from '../../assets/pictures/portfolio/software.gif';

export interface PortfolioProps {}

interface PortfolioBoxProps {
    icon: string;
    title: string;
    subtitle: string;
    route: string;
    iconStyle: React.CSSProperties;
}

const PortfolioBox: React.FC<PortfolioBoxProps> = ({
    icon,
    title,
    subtitle,
    route,
    iconStyle,
}) => {
    const [, setIsHovering] = useState(false);
    const navigation = useNavigate();

    const handleClick = () => {
        navigation(`/portfolio/${route}`);
    };

    const onMouseEnter = () => {
        setIsHovering(true);
    };

    const onMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <div
            onMouseDown={handleClick}
            className="big-button-container"
            style={styles.portfolioLink}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div style={styles.portfolioLinkLeft}>
                <img
                    src={icon}
                    style={Object.assign(
                        {},
                        styles.portfolioLinkImage,
                        iconStyle
                    )}
                    alt=""
                />
                <div style={styles.portfolioText}>
                    <h1 style={{ fontSize: 48 }}>{title}</h1>
                    <h3>{subtitle}</h3>
                </div>
            </div>
            <div style={styles.portfolioLinkRight}></div>
        </div>
    );
};

const Portfolio: React.FC<PortfolioProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Portfolio</h1>
            <br />
            <p>
                Click on one of the areas below to check out some of my
                portfolios I've done in that field. Enjoy!
            </p>
            <br />
            <div style={styles.portfolioLinksContainer}>
                <PortfolioBox
                    icon={skills}
                    iconStyle={styles.skillsIcon}
                    title="SKILLS"
                    subtitle="PORTFOLIO"
                    route="skills"
                />
                <PortfolioBox
                    icon={certificates}
                    iconStyle={styles.certificateIcon}
                    title="CERTIFICATES"
                    subtitle="PORTFOLIO"
                    route="certificates"
                />
                <PortfolioBox
                    icon={projects}
                    iconStyle={styles.projectsIcon}
                    title="PROJECTS"
                    subtitle="PORTFOLIO"
                    route="projects"
                />
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    portfolioLinksContainer: {
        flexDirection: 'column',
        width: '100%',
        display: 'flex',
        flex: 1,
    },
    portfolioLink: {
        marginBottom: 24,
        cursor: 'pointer',
        width: '100%',
        boxSizing: 'border-box',

        alignItems: 'center',
        justifyContent: 'space-between',
    },
    portfolioText: {
        justifyContent: 'center',
        flexDirection: 'column',
    },
    portfolioLinkImage: {
        width: 48,
        // height: 48,
        marginRight: 30,
    },
    portfolioLinkLeft: {
        marginLeft: 16,
        alignItems: 'center',
    },
    skillsIcon: {
        width: 80,
        height: 80,
    },
    certificateIcon: {
        width: 80,
        height: 100,
    },
    arrowIcon: {
        width: 48,
        height: 48,
    },
    projectsIcon: {
        width: 70,
        height: 70,
    },
};

export default Portfolio;
