import React from 'react';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <ResumeDownload />
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>TURKCELL Inc.</h1>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://www.turkcell.com.tr/'}
                        >
                            <h4>www.turkcell.com.tr</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Senior Offensive Security Expert</h3>
                        <b>
                            <p>10/2022 - Present</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                Turkcell is a converged telecommunication and technology services provider, founded and headquartered in Turkey. It serves its customers with voice, data, TV and value-added consumer and enterprise services on mobile and fixed networks
                </p>
                <br />
                <h3>Job Description</h3>
                <p>I perform Web Application, Local Network, Web API, Static and Dynamic Code Analysis, Mobile Application, Social Engineering, DDoS and WI-FI tests for applications developed internally and externally at Turkcell. I work directly with business analysts and software developers to close the vulnerabilities identified in security tests.</p>
                <ul>
                    <li>
                        <p>
                        Network and Web Application Penetration tests
                        </p>
                    </li>
                    <li>
                        <p>
                        Static Code Analysis with Fortify and SonarQube
                        </p>
                    </li>
                    <li>
                        <p>
                        Soap and Rest Web Service Penetration tests
                        </p>
                    </li>
                    <li>
                        <p>
                        IOS and Android Mobile Application Penetration tests
                        </p>
                    </li>
                    <li>
                        <p>
                        Web API and Cloud Penetration tests
                        </p>
                    </li>
                    <li>
                        <p>
                        DDoS and Social Engineering tests
                        </p>
                    </li>
                    <li>
                        <p>
                        WI-FI security tests
                        </p>
                    </li>
                    <li>
                        <p>
                        Writing Code in pentest projects
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>KPMG Turkey</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://kpmg.com/tr/tr/home.html'}
                        >
                            <h4>kpmg.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Senior Cyber Security Consultant</h3>
                        <b>
                            <p>12/2020 - 10/2022</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                KPMG Turkey is a professional audit, tax, and advisory firm that expands the traditional understanding of auditing and compliance to meet the needs of both local and international clients. By offering customized services tailored to specific industries and businesses, KPMG Turkey addresses the unique demands of its clients. Established in 1982, KPMG Turkey is the second-oldest international audit, tax, and advisory firm in the country. Its commitment is to consistently deliver high-quality professional services to clients worldwide.
                </p>
                <br />
                <h3>Job Description</h3>
                <ul>
                    <li>
                        <p>
                        Performing Internet, Intranet, Mobile Application and
                        Active Directory penetration tests and preparation of
                        reports
                        </p>
                    </li>
                    <li>
                        <p>
                        Management and Penetration testing processes of
                        Microsoft Google Cloud and Azure Cloud services.
                        </p>
                    </li>
                    <li>
                        <p>
                        Web API penetration tests. 
                        </p>
                    </li>
                    <li>
                        <p>
                        DDoS and Social Engineering tests.
                        </p>
                    </li>
                    <li>
                        <p>
                        Completing the BRSA tests and reporting processes
                        </p>
                    </li>
                    <li>
                        <p>
                        Active using of software such as Nessus, Acunetix, Netsparker and MOBSF. 
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>Lostar Information Security Inc.</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://lostar.com.tr'}
                        >
                            <h4>lostar.com.tr</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Cyber Security Specialist</h3>
                        <b>
                            <p>02/2020 - 12/2020</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                Lostar Inc., founded in 1998, based in Istanbul/Turkey, provides competitive edge in an uncertain
                world with Business and Technology Risk Consulting. Lostar helps companies secure their business
                assets and digital infrastructure by providing a wide range of customized consulting, auditing and
                training services. Core activities include assessing risks, designing security systems, developing and
                implementing strategies and undertaking reviews. The company combines the ability to identify
                the key issues and formulate strategies in the most complex and dynamic environments, with the
                know-how to provide practical support at the coalface. 
                </p>
                <br />
                <h3>Job Description</h3>
                <ul>
                    <li>
                        <p>
                        Performing Penetration tests for the Internet, Intranet, Social Engineering, DDoS and Mobile.
                        </p>
                    </li>
                    <li>
                        <p>
                        I took an active part in testing the rules in IBM QRadar in
                        the SecurityOperations Center (SOC)
                        </p>
                    </li>
                    <li>
                        <p>
                        In the completion of the BRSA, CMB and Penetration Test
                        Reporting processes.
                        </p>
                    </li>
                    <li>
                        <p>
                        In constant communication with customers and providing support. 
                        </p>
                    </li>
                    <li>
                        <p>
                        Active use of Nessus, Acunetix software and examination of vulnerabilities.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
