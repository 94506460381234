import React from 'react';
import ResumeDownload from '../ResumeDownload';

import webapp from '../../../assets/pictures/skills/webapp.gif';
import localnetwork from '../../../assets/pictures/skills/localnetwork.gif';
import activedirectory from '../../../assets/pictures/skills/activedirectory.gif';
import api from '../../../assets/pictures/skills/api.gif';
import mobile from '../../../assets/pictures/skills/mobile.gif';
import burp from '../../../assets/pictures/skills/burp.gif';
import cloud from '../../../assets/pictures/skills/cloud.gif';
import sast from '../../../assets/pictures/skills/sast.gif';
import dast from '../../../assets/pictures/skills/dast.gif';
import iast from '../../../assets/pictures/skills/iast.gif';
import phishing from '../../../assets/pictures/skills/phishing.gif';
import ddos from '../../../assets/pictures/skills/ddos.gif';
import wifi from '../../../assets/pictures/skills/wifi.gif';
import scantools from '../../../assets/pictures/skills/scantools.gif';

export interface SkillsPortfolioProps {}

interface Skill {
  name: string;
  icon: string;
  percentage: number;
  years: string;
  colorClass: string;
}

const skillsData: Skill[] = [
  { name: 'Web Applications', icon: webapp, percentage: 100, years: '6 YEARS', colorClass: 'bg-primary' },
  { name: 'Burp Suite', icon: burp, percentage: 100, years: '6 YEARS', colorClass: 'bg-primary' },
  { name: 'Scanning Products', icon: scantools, percentage: 100, years: '6 YEARS', colorClass: 'bg-primary' },
  { name: 'Phishing', icon: phishing, percentage: 100, years: '3 YEARS', colorClass: 'bg-primary' },
  { name: 'Denial Of Service', icon: ddos, percentage: 100, years: '3 YEARS', colorClass: 'bg-primary' },
  { name: 'Web API Tests', icon: api, percentage: 90, years: '3 YEARS', colorClass: 'bg-primary' },
  { name: 'Local Network', icon: localnetwork, percentage: 90, years: '4 YEARS', colorClass: 'bg-primary' },
  { name: 'Active Directory', icon: activedirectory, percentage: 80, years: '3 YEARS', colorClass: 'bg-primary' },
  { name: 'Mobile Application', icon: mobile, percentage: 80, years: '3 YEARS', colorClass: 'bg-primary' },
  { name: 'Wireless Security', icon: wifi, percentage: 80, years: '2 YEARS', colorClass: 'bg-primary' },
  { name: 'Static Application Security', icon: sast, percentage: 80, years: '2 YEARS', colorClass: 'bg-primary' },
  { name: 'Dynamic Application Security', icon: dast, percentage: 70, years: '2 YEARS', colorClass: 'bg-primary' },
  { name: 'Interactive Application Security', icon: iast, percentage: 70, years: '2 YEARS', colorClass: 'bg-primary' },
  { name: 'Cloud Security', icon: cloud, percentage: 30, years: '4 MONTHS', colorClass: 'bg-primary' },
];

const SkillsPortfolio: React.FC<SkillsPortfolioProps> = () => {
  return (
    <div className="site-page-content">
      <h1>My Skills</h1>
      <br />
      <p>Below are the skills I have acquired throughout my career.</p>

      <ul className="row list-unstyled skills" style={{ display: 'block' }}>
        {skillsData.map((skill, index) => (
          <li key={index} className="col-md-12 mb-3">
            <div className="row">

              {/* Image Section */}
              <div className="col-md-2 d-flex align-items-center">
                <img src={skill.icon} alt={skill.name} className="icon me-3" />
              </div>

              {/* Skill Name Section */}
              <div className="col-md-3 d-flex flex-column justify-content-center mt-4">
                <h4 className="skill-title m-0">{skill.name}</h4>
                <small className="text-muted mt-1">{skill.years}</small>
              </div>

              {/* Pixel Progress Bar Section */}
              <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="loader-bar">
                  {/* Always render 8 block-borders first */}
                  {Array.from({ length: 8 }, (_, idx) => (
                    <div key={idx} className="block-border"></div>
                  ))}

                  {/* Create blocks based on the percentage, dynamically rendering block-meter and block-border */}
                  {Array.from({ length: 10 }, (_, idx) => (
                    <div
                      key={idx + 8} 
                      className={`${idx < Math.floor(skill.percentage / 10) ? 'block-meter' : 'block-border'}`}
                      style={{
                        gridRow: 3,
                        gridColumn: (idx * 2) + 4,  // Positions blocks in grid
                        height: 25,
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <br />
      <ResumeDownload />
    </div>
  );
};

export default SkillsPortfolio;
